<template>
  <div class="d-flex my-3 global-breadcrumb align-self-center align-items-center">
    <i class="fa-solid fa-magnifying-glass"></i>
    <b-breadcrumb :items="items" class="pl-2"></b-breadcrumb>
  </div>
</template>

<script>
  export default {
    name: 'breadcrumb',
    components: {},
    props: ['data'],
    mixins: [],
    data: function () {
      return {
        icon: '',
        items: []
      }
    },
    methods: {
      updateItems: function() {
        this.items = this.$props.data.items
        this.icon = this.$props.data.icon
      }
    },
    watch: {
      $route() {
        this.updateItems()
      }
    },
    created: function() {
      this.updateItems()
    }
  }
</script>

<style lang="scss">
  .global-breadcrumb {
    i {
      color: #0E1F22;
      font-size: 1.2rem;
    }

    ol.breadcrumb {
      font-size: 0.9rem;
      padding: 0;
      margin: 0;
      background: none;
      border-radius: 0;

      & li + li:before {
        color: #979797;
        content: "> ";
        transform: scale(.8, 1.7);
      }

      .breadcrumb-item:first-child span {
        color: #0E1F22;
      }

      .breadcrumb-item {

        .router-link-active {
          color: #0E1F22;
        }

        &.active {
          color: #2331D0;
        }

        :hover {
          text-decoration: none;
        }
      }
    }
  }
</style>