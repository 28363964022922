<template>
  <b-modal ref="viewNotificationModal" modal-class="sent-notification-modal"  content-class="sent-notification-modal-content" size="lg" scrollable hide-footer>
    <template #modal-header>
      <div class="email-header">
        <p class="email-subject" v-html="get(notification, 'emailDynamicTemplateData.subject', 'N/A')"></p>
        <div class="email-close"><button type="button" aria-label="Close" class="close" @click.prevent="$refs['viewNotificationModal'].hide()">×</button></div>
        <div class="email-icon">{{ getInitials(notification.from) }}</div>
        <div class="email-from">{{ notification.from }}</div>
        <div class="email-sent">{{ formatDateTimeLongNoSeconds(notification.sentDate) }}</div>
        <div class="email-to"><strong>To: </strong> {{ getCsv(notification.to) }}</div>
      </div>
    </template>

    <iframe ref="sentNotificationIframe" id="sentNotificationIframe" name="sent-notification-iframe" class="sent-notification-iframe" />
  </b-modal>
</template>
<script>
import get from 'just-safe-get'
import dateMixin from "../mixins/dateMixin";
import ApiProperties from '../../../src/main/resources/application'

export default {
  name: "EmployerNotificationEmail",

  data() {
    return {
      get,
      sentNotificationId: null,
      retrievingSentNotification: false,
      emailSrc: null,
      notification: null
    }
  },

  mixins: [dateMixin],

  methods: {
    getCsv: function(tos) {
      let tosArray = []

      if (tos!==undefined && tos!=null) {
        for (let i=0; i<tos.length; i++) {
          tosArray.push(tos[i].email);
        }
      }

      return tosArray.join(", ")
    },

    getInitials(from) {
      if (from!==undefined && from!==null && from.length>0) {
        let parts = from.split(" ")
        if (parts.length>1) {
          return `${parts[0].charAt(0)} ${parts[1].charAt(0)}`
        } else if (parts.length>0) {
          return `${parts[0].charAt(0)}`
        } else {
          let char1 = from.charAt(0)
          let char2 = from.charAt(1)

          return (char1==='<') ? char2 : char1
        }
      } else {
        return ""
      }
    },

    getSavedNotificationAndOpenModal(sentNotificationId) {
      this.$http.get(ApiProperties.api.notificationDataBySentNotificationId, {pathVars: {sentNotificationId: sentNotificationId}}).then(response => {
        this.notification = response.data;
        this.$refs.viewNotificationModal.show()
        this.$nextTick(() => {
          let src = `${process.env.VUE_APP_AXIOS_BASE}${ApiProperties.api.notificationHtml.replace("{externalNotificationId}", this.notification.id)}`
          console.log(`Attempting to get notificaiton html with id=${this.notification.id}`)
          this.$refs.sentNotificationIframe.setAttribute("src", src)
        })
      }).catch(e => {
        this.errors.push(e);
      })
    },

    getNotificationData(externalNotificationId) {
      this.$http.get(ApiProperties.api.notificationData, pathParams).then(response => {
        this.notification = response.data;
      }).catch(e => {
        this.errors.push(e);
      })

    }
  },

  created() {
    let _self = this

    window.addEventListener('message', function(e) {
      if (e.data.frameHeight) {
        _self.$refs.sentNotificationIframe.style.height = `${e.data.frameHeight+35}px`
      }
    });
  }
}
</script>
<style lang="scss">

.sent-notification-modal {
  .modal-dialog {
    &.modal-lg {
      @media (min-width: 992px) {
        max-width: 903px;
        background-color:#F7F5F2;
      }
    }

    .modal-content {
      &.sent-notification-modal-content {
        font-size: .8em;
        padding: 0;
        overflow-x: hidden;

        .modal-header {
          padding:0 0 5px 0;
        }

        .modal-body {
          padding:0;
        }

        .email-subject {
          grid-area: email-subject;
          padding:0;
          margin:0;
          font-weight: bold;
        }

        .email-icon {
          grid-area: email-icon;
          width: 35px;
          height: 35px;
          line-height: 35px;
          border-radius: 50%;
          font-size: 10px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          background: #000;
          margin: 5px;
          justify-self: center;
        }

        .email-from {
          grid-area: email-from;
          font-weight: bold;
        }

        .email-sent {
          grid-area: email-sent;
          color: #bbbbbb;
          font-size: 11px;
          text-align: right;
          padding-right: 5px;
        }

        .email-to {
          grid-area: email-to;
        }

        .email-close {
          grid-area: email-close;
          font-size: .9em;
          text-align: right;
          padding-right: 5px;
        }

        .email-header {
          display: grid;
          grid-row-gap: 5px;
          margin: 8px 8px 0 8px;
          grid-template-columns: 60px 615px 215px;
          grid-template-areas:
        "email-subject email-subject  email-close"
        "email-icon    email-from     email-sent"
        "email-icon    email-to       email-to";

        }
      }

      .sent-notification-iframe {
        border: 0;
        width: 100%;
        height: 600px;
      }
    }
  }
}

</style>