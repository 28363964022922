export default {
    data: () => {
        return {}
    },
    methods: {
        /**
         * The URL should be a rest endpoint that returns a byte[] that we turn into a blob and the appropriate headers,
         * which include inline and the filename so the browser attempts to open the file first.
         *
         * This will try to have the browser OPEN the file instead of download the file.
         * @param url
         * @param payload
         */
        openFile(url, payload) {
            console.log(`Getting response from ${url} and payload=${JSON.stringify(payload)}`)

            let request = (payload)
                ? this.$http({url, method: 'POST', data: payload, responseType: 'blob'})
                : this.$http({url, method: 'GET', responseType: 'blob'})

            request.then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const downloadUrl = window.URL.createObjectURL(blob);

                // Attempt to open the PDF inline in a new tab.
                window.open(downloadUrl, '_blank');
            }).catch(error => {
                console.error('Download failed:', error);
            });
        },

        /**
         * The URL should be a rest endpoint that returns a byte[] that we turn into a blob and the appropriate headers,
         * which include setting the contentDispositionFormData to "attachment"
         *
         * This will actually download the file.
         * @param url
         * @param payload
         */
        downloadFile(url, payload) {
            console.log(`We have url=${url} and payload= ....`)
            console.dir(payload)
            let request = (payload)
                ? this.$http({url, method: 'POST', data: payload, responseType: 'blob'})
                : this.$http({url, method: 'GET', responseType: 'blob'})

            request.then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // CG: CREATE LINK: This feels like a hack, but it seems to be the only way to do this from a rest call
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;

                // Use the 'content-disposition' set in the rest controller header to get the original file name
                // (in most cases the filename is just a hash and not relavent, but this should be versatile to work for all blob/byte[] controller responses)
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'larkin-download'; // Default filename
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = fileNameMatch[1];
                    }
                }

                //setting the element up so we can fool the browser to think its clicking a link to the file (has to be a better way)
                link.setAttribute('download', fileName); // Set the download attribute of the link
                document.body.appendChild(link);
                link.click();

                // Clean up and revoke the object URL
                link.remove();
                window.URL.revokeObjectURL(downloadUrl);
                //END CREATE LINK
            }).catch(error => {
                console.error('Download failed...:', error);
            });
        }
    }
}