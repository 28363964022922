<template>
  <div class="employee-detail m-4 pb-5">
    <vue-confirm-dialog></vue-confirm-dialog>
    <Breadcrumb v-bind:data="this.$props.breadcrumb" />
    <div class="larkin-enclosure-box desktop-width-only mb-3" v-if="employee.lastName">
      <div style="display: flex">
        <img style="margin-right: 8px" src="/img/icons/employee-icon.svg" />
        <h4>{{employee.firstName}} {{employee.lastName}}</h4>
      </div>


      <div class="d-flex flex-row" style="margin-top: -1rem">

        <!-- Left Column -->
        <div class="flex-column left-col flex-wrap">
          <div class="detail-header">&nbsp;</div>
          <div class="d-flex">
            <div class="header-table-col-wrap mr-5">
              <div class="d-inline-flex data-group" v-if="employee.address">
                <div class="data-header" style="width: 7em;">Address</div>
                <div class="data-detail">
                  <div>{{employee.address.address}}</div>
                  <div v-if="employee.address.address2">{{employee.address.address2}}</div>
                  <div>{{employee.address.city}}, {{employee.address.state}} {{employee.address.zip}}</div>
                  <div v-if="employee.address.country">{{employee.address.country}}</div>
                </div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Employee ID</div>
                <div class="data-detail">{{employee.employeeId}}</div>
              </div>
            </div>
            <div class="header-table-col-wrap">
              <div class="d-inline-flex data-group" v-if="employee.phone">
                <div class="data-header" style="width: 7em;">Work Phone</div>
                <div class="data-detail">{{employee.phone}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Work Email</div>
                <div class="data-detail">{{employee.email}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="flex-column" v-if="employee.employer">
          <div class="detail-header">Employer Information</div>
          <div class="d-flex">
            <div class="header-table-col-wrap mr-5">
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 5em;">Employer</div>
                <div class="data-detail">{{employee.employer.name}}</div>
              </div>
              <div class="d-inline-flex data-group" v-if="employee.location">
                <div class="data-header" style="width: 5em;">Location</div>
                <div class="data-detail">{{employee.location}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 5em;">Title</div>
                <div class="data-detail">{{employee.jobTitle}}</div>
              </div>
            </div>
            <div class="header-table-col-wrap">
              <div class="d-inline-flex data-group" v-if="employee.address">
                <div class="data-header">Address</div>
                <div class="data-detail">
                  <div>{{employee.employer.address.address}}</div>
                  <div v-if="employee.employer.address.address2">{{employee.employer.address.address2}}</div>
                  <div>{{employee.employer.address.city}}, {{employee.employer.address.state}} {{employee.employer.address.zip}}</div>
                  <div v-if="employee.employer.address.country">{{employee.employer.address.country}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-tabs content-class="larkin-enclosure-box desktop-width-only" v-model="tabs.idx">
      <!-- Leaves Tab -->
      <b-tab title="Leaves" :title-item-class="{'d-none': !(employee.hasLeave)}" :disabled="!(employee.hasLeave)">
        <table role="table" aria-busy="false" aria-colcount="9" class="table b-table leave-table table-row-click table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col" aria-colindex="1" class="">Leave #</th>
              <th role="columnheader" scope="col" aria-colindex="2" class="">Type</th>
              <th role="columnheader" scope="col" aria-colindex="3" class="">Status</th>
              <th role="columnheader" scope="col" aria-colindex="4" class="leave-col-from">From</th>
              <th role="columnheader" scope="col" aria-colindex="5" class="leave-col-through">Through</th>
              <th role="columnheader" scope="col" aria-colindex="6" class="leave-col-weeks">Weeks Used</th>
              <th role="columnheader" scope="col" aria-colindex="7" class="leave-col-protection">Protections</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row" tabindex="0" v-for="leave in employee.leaves" :key="leave.leaveId" @click="selectLeave(leave.leaveId)">
              <td aria-colindex="1" role="cell">{{leave.leaveId}}</td>
              <td aria-colindex="2" role="cell">{{leave.leaveType}}</td>
              <td aria-colindex="3" role="cell">{{getStatusString(leave)}}</td>
              <td colspan="4" class="schedule-sub-table">
                <div v-for="detail in leave.details" class="d-inline-flex flex-row">
                  <div class="leave-col-from">{{formatDate(detail.from)}}</div>
                  <div class="leave-col-through">{{formatDate(detail.to)}}</div>
                  <div class="leave-col-weeks">{{detail.workWeeks}}</div>
                  <div class="leave-col-protection">{{getProtectionNames(detail.protections)}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>

      <!-- Claims Tab -->
      <b-tab title="Claims" :title-item-class="{'d-none': !(employee.hasClaim)}" :disabled="!(employee.hasClaim)">
        <b-table :items="employee.claims" :fields="fields.claims" @row-clicked="selectClaim" :sort-compare="sortDateStrings"></b-table>
      </b-tab>

      <!-- Payments Tab -->
      <b-tab title="Payments" :title-item-class="{'d-none': !(employee.hasPayment)}" :disabled="!(employee.hasPayment)">
        <b-table :items="employee.payments" :fields="fields.payments" :sort-by.sync="sortBy" :sort-desc="true" @row-clicked="selectPayment" class="payment-table" :sort-compare="sortDateStrings">
          <template v-slot:cell(transactionPeriod)="data">
            {{data.item.periodStartDate}} - {{data.item.periodEndDate}}
          </template>

          <template v-slot:cell(transactionType)="data">
            <ul class="list-unstyled transaction-type">
              <li>{{data.item.transactionType}}</li>
              <li>{{data.item.planName}} #{{data.item.claimId}}</li>
            </ul>
          </template>
        </b-table>
      </b-tab>

      <!-- History Tab -->
      <b-tab title="History" :title-item-class="{'d-none': !(hasHistories)}" :disabled="!(hasHistories)">
        <table role="table" aria-busy="false" aria-colcount="9" class="table b-table histories-table table-hover">
          <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col" aria-colindex="1" class="col-date">Date</th>
            <th role="columnheader" scope="col" aria-colindex="2" class="col-type">Type</th>
            <th role="columnheader" scope="col" aria-colindex="3" class="col-description">Description</th>
            <th role="columnheader" scope="col" aria-colindex="4" class="col-action">Action</th>
          </tr>
          </thead>
          <tbody role="rowgroup">
          <tr role="row" tabindex="0" v-for="history in histories" :key="history.id">
            <td aria-colindex="1" role="cell">{{formatDateTimeNoSeconds(history.date)}}</td>
            <td aria-colindex="2" role="cell">{{history.type}}</td>
            <td aria-colindex="3" role="cell"><span v-html="history.description || 'N/A'" /><span v-if="history.userNotes"> - {{history.userNotes}}</span> </td>
            <td aria-colindex="4" role="cell">
              <div class="d-flex flex-row align-items-center justify-content-between">
                <a href="#" @click.prevent="openHistoryItem(history)" class="view-download-link">
                  <span v-if="isSentNotification(history)"><i class="icon icon-eye mr-2"></i> View</span>
                  <span v-else><i class="icon mr-2" :class="getFileIcon(history)"></i> {{(isStreamingFile(history) ? "View" : "Download")}}</span>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </b-tab>

      <div v-if="tabs.noData">This employee has no leaves, claims, notifications, or payments.</div>
    </b-tabs>

    <ViewNotificationEmail ref="viewNotification" />
  </div>
</template>

<script>
  import Breadcrumb from '@/components/Breadcrumb.vue'
  import ApiProperties from '../../../src/main/resources/application'
  import dateMixin from '../mixins/dateMixin.js';
  import leaveMixin from '../mixins/leaveMixin.js';
  import fileDownload from "../mixins/fileDownload";
  import ViewNotificationEmail from '../components/ViewNotificationEmail.vue'

  export default {
    name: 'employeeDetail',
    components: {Breadcrumb, ViewNotificationEmail},
    props: ['employeeId', 'breadcrumb'],
    mixins: [dateMixin, leaveMixin, fileDownload],
    data: function() {

      // setting hasLeave to true and leaves to an array for active tab to work
      return {
        tabs: {
          idx: 0,
          noData: false
        },

        employee: {
          hasLeave: true,
          leaves: []
        },

        histories: [],
        hasHistories: false,

        sortBy: 'date',

        fields: {
          claims: [
              { key: "id", label: "Claim #", sortable: true},
              { key: "status"},
              { key: "disabilityDate", sortable: true},
              { key: "expectedBenefitEndDate", sortable: true},
              { key: "planTypeName", label: 'Plan Type'}
          ],

          payments: [
            { key: "totalFormatted", label: "Amount", sortable: true},
            { key: "payable", label: "Payable To/From"},
            { key: "date", sortable: true},
            { key: "checkNo", label: "Check #", sortable: true},
            { key: "transactionPeriod", label: "Period", sortable: true},
            { key: "transactionType", label: "Type"}
          ]
        }
      }
    },
    methods: {
      isStreamingFile(history) {
        return history.context===this.$constants.HistoryContext.WITH_STREAMING_PORTALFILE_HISTORY
      },

      getFileIcon(history) {
        return this.isStreamingFile(history) ? 'icon-eye' : 'icon-download-grey'
      },

      isSentNotification(history) {
        return history.context===this.$constants.HistoryContext.WITH_NOTIFICATION_HISTORY
      },

      openHistoryItem(history) {
        switch(history.context) {
          case this.$constants.HistoryContext.WITH_STREAMING_PORTALFILE_HISTORY:
          case this.$constants.HistoryContext.WITH_PORTALFILE_HISTORY:
          case this.$constants.HistoryContext.WITH_PORTALFILE_STATECHANGE:
            let payload = {
              attachmentHistoryId: history.extAttachmentHistoryId,
              attachmentVersion: history.extAttachmentHistoryVersion
            }
            if (payload.attachmentHistoryId===null) {
              this.$bvToast.toast('This feature is not available for this upload.');
            } else {
              if (this.isStreamingFile(history)) {
                this.openFile(ApiProperties.api.portalFileGet, payload)
              } else {
                this.$confirm(
                    {
                      message: 'Warning! This may be a sensitive document that will be downloaded to your computer. Please permanently delete the file after reviewing. Would you like to continue?',
                      button: {
                        no: 'No',
                        yes: 'Yes'
                      },
                      callback: confirm => {
                        if (confirm) {
                          this.downloadFile(ApiProperties.api.portalFileGet, payload)
                        }
                      }
                    }
                )
              }
            }
            break;
          case this.$constants.HistoryContext.WITH_NOTIFICATION_HISTORY:
            this.$refs.viewNotification.getSavedNotificationAndOpenModal(history.sentNotificationId)
            break;
        }
      },

      selectLeave(leaveId) {
        this.$router.push({ name: 'employeeLeaveDetail', params: { leaveId } })
      },

      selectClaim(claimInfo) {
        //do nothing for v1
      },

      selectPayment(paymentInfo) {
        //do nothing for v1
      },

      getStartDateFromLineItems(lineItems) {
        let startDate = null

        for (let lineItem of lineItems) {
          startDate = lineItem.startDate
        }

        return startDate
      },

      sortDateStrings(a, b, key) {
        const isDateField = (key.toLowerCase().indexOf("date") !== -1)
        const isPeriodField = (key.toLowerCase().indexOf("period") !== -1)

        if (isDateField || isPeriodField) {
          try {
            let aAsDate = new Date(a[key] || this.getStartDateFromLineItems(a.lineItems))
            let bAsDate = new Date(b[key] || this.getStartDateFromLineItems(b.lineItems))

            return aAsDate - bAsDate
          } catch(e) {
            console.error(e)
          }
        } else {
          // Let b-table handle sorting other fields (other than `date` field)
          return false
        }
      },

      getEmployeeData() {
        this.$http.get(ApiProperties.api.employeeDetail, { pathVars: { employeeId: this.employeeId }}).then(response => {
          this.employee = response.data;

          if (!this.employee.hasLeave) {
            this.tabs.idx = null;

            if (!this.employee.hasClaim && !this.employee.hasPayment) {
              this.tabs.noData = true;
            }
          }
        }).catch(e => {
          this.errors.push(e);
        })
      },

      getEmployeeHistory() {
        let config = {
          headers: {
            'Content-Type': 'application/json'
          },

          pathVars: { employeeId: this.employeeId }
        }

        let payload = {
          contexts: [this.$constants.HistoryContext.WITH_PORTALFILE_HISTORY, this.$constants.HistoryContext.WITH_STREAMING_PORTALFILE_HISTORY, this.$constants.HistoryContext.WITH_NOTIFICATION_HISTORY, this.$constants.HistoryContext.WITH_PORTALFILE_STATECHANGE]
        }

        this.$http.post(ApiProperties.api.employeeHistory, payload, config).then(response => {
          this.histories = response.data
          if (this.histories.length>0) {
            this.hasHistories = true
            this.tabs.noData = false
          } else {
            this.hasHistories = false
          }
        })
      }
    },

    created: function() {
      this.getEmployeeData()
      this.getEmployeeHistory()
    }
  }
</script>

<style lang="scss">
@import '../scss/app.scss';
@import '../scss/leave-table.scss';
@import '../scss/histories-table.scss';

.employee-detail {
  font-size: .80rem;
  max-width: 1333px;

  .larkin-enclosure-box {
    &.desktop-width-only {
      min-width: 1150px;
      max-width: 2000px;
    }

    h4 {
      margin: 8px 0 20px 0;
    }
  }

  .left-col {
    margin-right: 75px;
  }

  .right-col {
    margin-right: 75px;
  }

  .detail-header {
    font-size: .75rem;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .payment-table {
    &.table.b-table {
      td {
        vertical-align: middle;
      }
    }
    ul.transaction-type {
      line-height: 1.2rem;
      margin:0;
    }
  }

  .view-download-link {
    &:hover {
      text-decoration:none;
    }
  }
}
</style>
