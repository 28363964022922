import leaveIcons from './leaveIcons';
import ApiProperties from '../../../src/main/resources/application';
import get from 'just-safe-get';
import dateMixin from '../mixins/dateMixin.js';

const isString = function(testVal) {
  return (typeof testVal === 'string');
};

const classWithSize = function(className, size) {
  return className+"_"+size;
};

const status = {
  NONE: "NONE",
  COMPLETED: "COMPLETED",
  FORM_PENDING_CLIENT: "FORM_PENDING_CLIENT",
  FORM_PENDING_LARKIN: "FORM_PENDING_LARKIN",
  FORM_COMPLETED: "FORM_COMPLETED",
  PENDING_CLIENT: "PENDING_CLIENT",
  PENDING_LARKIN: "PENDING_LARKIN",
  UPLOAD_PENDING_LARKIN: "UPLOAD_PENDING_LARKIN",
  UPLOAD_PENDING_CLIENT: "UPLOAD_PENDING_CLIENT",
  UPLOAD_COMPLETED: "UPLOAD_COMPLETED",
  WORKFLOW_PENDING_CLIENT: "WORKFLOW_PENDING_CLIENT",
  WORKFLOW_STARTED: "WORKFLOW_STARTED",
  WORKFLOW_PENDING_LARKIN: "WORKFLOW_PENDING_LARKIN",
  WORKFLOW_COMPLETED: "WORKFLOW_COMPLETED"

};

const leaveScheduleTypes = {
  INTERMITTENT: "Intermittent",
  BLOCK: "Block",
  REDUCED: "Reduced",
  MANUAL: "Manual"
}

const leaveCoverages = {
  AT_WORK_ACCOMODATION: {
    icon: "icon-leave-atworkaccomodation",
    matchers: ["At Work Accommodation - Medical", "At Work Accommodation - WC"],
  },
  BEREAVEMENT: {
    icon: "icon-leave-bereavement",
    matchers: ["Bereavement"],
  },
  FAMILY_CARE: {
    icon: "icon-leave-familycare",
    matchers: ["Family Care", "Family Care (Canada)", "Sick Child"],
  },
  GRADUAL_RETURN_TO_WORK: {
    icon: "icon-leave-gradualreturntowork",
    matchers: ["Gradual Return to Work"],
  },
  MEDICAL: {
    icon: "icon-leave-medical",
    matchers: ["Medical", "Medical (Canada)", "Personal Medical", "Organ/Bone Marrow Donation"],
  },
  MILITARY: {
    icon: "icon-leave-military",
    matchers: ["Military", "Military (Canada)", "Military Exigency", "Military Family Care"],
  },
  PARENTAL: {
    icon: "icon-leave-parental",
    matchers: ["Parental", "Parental (Canada)"],
  },
  PERSONAL: {
    icon: "icon-leave-personal",
    matchers: ["Personal", "Educational", "Sabbatical", "Jury Duty", "Other", "Historical", "Meal Only"],
  },
  PREGNANCY: {
    icon: "icon-leave-pregnancy",
    matchers: ["Pregnancy", "Pregnancy (Canada)", "Pre Pregnancy", "Post Pregnancy"],
  },
  WORKERS_COMP: {
    icon: "icon-leave-workerscomp",
    matchers: ["Workers' Comp"],
  },
  DEFAULT: {
    icon: "icon-leave-personal",
    matchers: []
  }
}

const LEAVE_COLORS = [
  {className: 'leave-blue', hex: '#3182ce'},
  {className: 'leave-yellow', hex: '#d69e2e'},
  {className: 'leave-teal', hex: '#319795'},
  {className: 'leave-pink', hex: '#d53f8c'},
  {className: 'leave-indigo', hex: '#5a67d8'},
  {className: 'leave-orange', hex: '#dd6b20'},
  {className: 'leave-green', hex: '#38a169'},
  {className: 'leave-red', hex: '#e53e3e'},
  {className: 'leave-purple', hex: '#805ad5'},
]

export default {
  data: function() {
    return {
      file: {},
      errors: [],
      uploading: false,
      actionItemStatus: status
    }
  },

  mixins: [dateMixin],

  methods: {
    downloadFileLink: function(id) {
      return process.env.VUE_APP_AXIOS_BASE + ApiProperties.api.todoDownloadByItemId.replace(`{itemId}`, id);
    },

    /**
     * This slightly changes the format from the backend. The backend has a scheduleDetails array with a dateRanges
     * array inside of that. Were mapping that down to include dateranges within the scheduleDetails array.
     *
     * @param leave
     * @returns {scheduleDetails}
     * @private
     */
    flattenScheduleDetailsFromDetailsAndDateRanges(leave) {
      let scheduleDetails = null;

      if (leave.details !== null && leave.details.length > 0) {
        scheduleDetails = [];
        for (let i=0; i<leave.details.length; i++) {
          let scheduleDetail = leave.details[i];

          if (scheduleDetail.dateRanges !== null && scheduleDetail.dateRanges.length > 0) {
            for (let j=0; j<scheduleDetail.dateRanges.length; j++) {
              let dateRange = scheduleDetail.dateRanges[j];

              scheduleDetails.push({
                leaveId: leave.leaveId,
                type: dateRange.type,
                from: this.formatDate(dateRange.from),
                to: this.formatDate(dateRange.to),
                period: this.getLeavePeriodDateRangeString(dateRange.from, dateRange.to),
                hours: dateRange.hours,
                exportSuppressed: dateRange.exportSuppressed
              });
            }
          }
        }
      }

      return scheduleDetails;
    },

    /**
     * Checks to see if this is a block leave as blocks will be treated slightly different than intermittent/manual/reduced
     *
     * @param leave
     * @returns {boolean}
     */
    isBlockLeave(leave) {
      let scheduleType = this.getLeaveScheduleType(leave);

      return scheduleType==='Block'
    },

    getLeaveScheduleType(leave) {
      return get(leave, 'details.0.leaveScheduleType');
    },

    getLeavePeriodDateRangeString(startDate, endDate, formatOptions) {
      if (startDate !== null && endDate !== null) {
        return `${this.formatDate(startDate, formatOptions)} - ${this.formatDate(endDate, formatOptions)}`;
      } else {
        return (startDate==null) ? this.formatDate(endDate, formatOptions) : this.formatDate(startDate, formatOptions);
      }
    },

    getLeaveCardDateRangeString(leave, formatOptions) {
      let dateRangeString = this.getLeavePeriodDateRangeString(leave.startDate, leave.endDate, formatOptions)
      let workWeeks = get(leave, 'totalWeeks')
      let weekLabel = (parseFloat(workWeeks)===1.0) ? "week" : "weeks"

      return `${dateRangeString} (${workWeeks} ${weekLabel})`
    },

    getDaysRemaining(leave) {
      let daysRemainObject = {
        total: -1,
        text: "Days of leave remaining",
        show: true
      }

      if (leave.endDate===null || leave.endDate===undefined) {
        daysRemainObject.show = false
        return daysRemainObject
      }

      const oneDay = 24 * 60 * 60 * 1000;
      let today = new Date().getTime()
      let startDate = this.getDateObject(leave.startDate).getTime()
      let endDate = this.getDateObject(leave.endDate).getTime()

      if (today<startDate) {
        daysRemainObject.text = "Days until leave begins"
        daysRemainObject.total = Math.ceil(Math.abs((startDate - today) / oneDay))
        return daysRemainObject
      }

      if (today>endDate) {
        daysRemainObject.total = 0
        return daysRemainObject
      }

      daysRemainObject.total = Math.ceil(Math.abs((today - endDate) / oneDay))
      return daysRemainObject
    },

    /*eslint no-console: ["error", { allow: ["warn", "error"] }] */
    getIconClassFromLeaveType: (size, leaveType, gender, section, bucket) => {
      let sizes = ['sm','lg'];
      let genders = ['M', 'F'];
      let sections = ['generic', 'todo-list'];

      if (sizes.indexOf(size) === -1) {
        console.error("ERROR: size ("+size+") is an unknown size!");
        return null;
      }

      // Expected: Unknown genders need to be randomized to either M or F for the short-term, working on a long-term fix
      if (genders.indexOf(gender) === -1) {
        let randomIndex = Math.round(Math.random());
        let moreGenderValues = ['MALE', 'FEMALE'];

        // 0 or 1
        let genderIndex = (moreGenderValues.indexOf(gender) === -1) ? randomIndex : moreGenderValues.indexOf(gender);
        gender = genders[genderIndex];
      }

      if (sections.indexOf(section) === -1) {
        console.error("ERROR: section ("+section+") is an unknown section!");
        return null;
      }

      let leaveTypeToIconClass = leaveIcons[section];
      let defaultClassName = (isString(leaveTypeToIconClass[gender])) ? leaveTypeToIconClass[gender] : leaveTypeToIconClass[gender][bucket];

      if (leaveTypeToIconClass[leaveType]) {
        if (isString(leaveTypeToIconClass[leaveType])) {
          return classWithSize(leaveTypeToIconClass[leaveType], size);
        }

        if (leaveTypeToIconClass[leaveType][gender]) {
          if (isString(leaveTypeToIconClass[leaveType][gender])) {
            return classWithSize(leaveTypeToIconClass[leaveType][gender], size);
          }

          if (leaveTypeToIconClass[leaveType][gender][bucket]) {
            return classWithSize(leaveTypeToIconClass[leaveType][gender][bucket], size);
          }
        }
      }

      return classWithSize(defaultClassName, size);
    },

    getIconImageFromClassName: function(className, size) {
      return (size==='sm') ? `/img/ee/${className.replace('_sm', '')}_400x495.png` : `/img/ee/${className.replace('_lg', '')}_739x914.png`;
    },

    getProgressBarClass: function(state) {
      return (state) ? state.toLowerCase().replace(/_/g, '-') : 'not-started';
    },

    getProgressBarLabel: function(percentage) {
      if (percentage===0) {
        return "Not Started";
      }

      if (percentage===100) {
        return "Completed";
      }

      return `${percentage}%`;
    },

    uploadFileInputId: function(itemId) {
      return `file-input-${itemId}`;
    },

    isConfirm: function(actionItem) {
      return actionItem.type==='CONFIRM'
    },
    isNone: function(actionItem) {
      return actionItem.status===this.actionItemStatus.NONE;
    },
    isCompleted: function(actionItem) {
      const remainingItemStatuses = [
        // Confirmation Tasks
        this.actionItemStatus.PENDING_CLIENT,
        // e-Forms, Native Forms, Uploads
        this.actionItemStatus.WORKFLOW_PENDING_CLIENT,
        this.actionItemStatus.WORKFLOW_STARTED,
        this.actionItemStatus.FORM_PENDING_CLIENT,
        this.actionItemStatus.UPLOAD_PENDING_CLIENT
      ];

      return !remainingItemStatuses.includes(actionItem.status);
    },
    isUploadCompleted: function(actionItem) {
      return actionItem.status===this.actionItemStatus.UPLOAD_COMPLETED;
    },
    isPendingClient: function(actionItem) {
      return actionItem.status===this.actionItemStatus.PENDING_CLIENT;
    },
    isPendingLarkin: function(actionItem) {
      return actionItem.status===this.actionItemStatus.PENDING_LARKIN;
    },
    isUploadPendingLarkin: function(actionItem) {
      return actionItem.status===this.actionItemStatus.UPLOAD_PENDING_LARKIN;
    },
    isWaitingForReview: function(actionItem) {
      return this.isPendingLarkin(actionItem) || this.isUploadPendingLarkin(actionItem) || this.isWorkflowPendingLarkin(actionItem)
    },
    isUploadPendingClient: function(actionItem) {
      return actionItem.status===this.actionItemStatus.UPLOAD_PENDING_CLIENT;
    },
    isWorkflowPendingClient: function(actionItem) {
      return actionItem.status===this.actionItemStatus.WORKFLOW_PENDING_CLIENT;
    },
    isWorkflowStarted: function(actionItem) {
      return actionItem.status===this.actionItemStatus.WORKFLOW_STARTED;
    },
    isWorkflowPendingLarkin: function(actionItem) {
      return actionItem.status===this.actionItemStatus.WORKFLOW_PENDING_LARKIN;
    },
    isWorkflowComplete: function(actionItem) {
      return actionItem.status===this.actionItemStatus.WORKFLOW_COMPLETED;
    },
    canUpload: function(actionItem) {
      return this.isUploadPendingClient(actionItem) || this.isUploadPendingLarkin(actionItem) || this.isUploadCompleted(actionItem);
    },
    getProtectionNames(protections) {
      if (protections===null) {
        return;
      }

      let protectionsArray = protections.map(p => p.name);

      return protectionsArray.join(", ");
    },
    isManualLeaveSchedule(leaveSchedule) {
      return (leaveSchedule.leaveScheduleType===leaveScheduleTypes.MANUAL)
    },
    isReducedLeaveSchedule(leaveSchedule) {
      return (leaveSchedule.leaveScheduleType===leaveScheduleTypes.REDUCED)
    },
    isIntermittentLeaveSchedule(leaveSchedule) {
      return (leaveSchedule.leaveScheduleType===leaveScheduleTypes.INTERMITTENT)
    },
    isBlockLeaveSchedule(leaveSchedule) {
      return (leaveSchedule.leaveScheduleType===leaveScheduleTypes.BLOCK)
    },

    isBlock(samLeaveSchedule) {
      return (samLeaveSchedule.type===leaveScheduleTypes.BLOCK)
    },

    isIntermittent(samLeaveSchedule) {
      return (samLeaveSchedule.type===leaveScheduleTypes.INTERMITTENT)
    },

    isManual(samLeaveSchedule) {
      return (samLeaveSchedule.type===leaveScheduleTypes.MANUAL)
    },

    isReduced(samLeaveSchedule) {
      return (samLeaveSchedule.type===leaveScheduleTypes.REDUCED)
    },


    hasDateRangeBlock(leaveSchedule) {
      return (leaveSchedule.hasOwnProperty("dateRanges") && leaveSchedule.dateRanges!==null && leaveSchedule.dateRanges.length>0)
    },
    hasReducedSchedules(leaveSchedule) {
      return (leaveSchedule.hasOwnProperty('reducedWorkSchedules') && leaveSchedule.reducedWorkSchedules!==null && leaveSchedule.reducedWorkSchedules.length>0)
    },
    hasAllBlockLeaveSchedules(leave) {
      let allBlock = true;
      for (let ls of leave.details) {
        if (!this.isBlockLeaveSchedule(ls)) {
          allBlock = false
        }
      }

      return allBlock
    },
    isFirstLeaveScheduleForBlock(leave, leaveSchedule) {
      return (leave.details[0]===leaveSchedule)
    },
    getRtwText(confirmReturnToWork, leaveSchedule, skipLeaveTypeCheck, leave) {
      if (!skipLeaveTypeCheck && (this.isIntermittentLeaveSchedule(leaveSchedule) || this.isManualLeaveSchedule(leaveSchedule) || this.isReducedLeaveSchedule(leaveSchedule))) {
        return this.$constants.DateUnavailablePlaceholder
      }

      // if all leave schedules are block, then the subsequent schedules will always show a blank string for return confirmed
      if (!!leave && this.hasAllBlockLeaveSchedules(leave) && !(this.isFirstLeaveScheduleForBlock(leave, leaveSchedule))) {
        return ""
      }

      //coerce value to boolean
      return (!!confirmReturnToWork) ? "Yes" : "No"
    },
    getFdaNotApprovedText(fdaNotApproved) {
      // coerce value to boolean
      return (!!fdaNotApproved) ? "Yes" : "No"
    },
    isParentalType(leaveType) {
      if (!leaveType)
        return false

      const typeArray = ['pregnancy', 'parental', 'parental (canada)', 'pregnancy (canada)']

      return (typeArray.includes(leaveType.toLowerCase()))
    },

    isPregnancy(leaveType) {
      if (!leaveType)
        return false

      const typeArray = ['pregnancy', 'pregnancy (canada)']

      return (typeArray.includes(leaveType.toLowerCase()))
    },

    getStatusString(leave) {
      if (leave.closed) {
        //return `Closed ${this.formatDate(leave.closeDate)}`
        return `Closed`
      }

      //return `Opened ${this.formatDate(leave.createDate)}`
      return `Opened`
    },

    getLeaveIconByLeaveCoverageType(leaveCoverageType) {
      for (let key in leaveCoverages) {
        if (leaveCoverages.hasOwnProperty(key)) {
          if (leaveCoverages[key].matchers.includes(leaveCoverageType)) {
            return leaveCoverages[key].icon
          }
        }
      }

      return leaveCoverages.DEFAULT.icon
    },

    getLeaveColorCodeObject(leaveIndex) {
      if (leaveIndex<LEAVE_COLORS.length)
        return LEAVE_COLORS[leaveIndex]
      else
        return LEAVE_COLORS[leaveIndex-LEAVE_COLORS.length]
    }
  },

}

